import { provider } from '@weavelab/frontend-connect';
import { frontendConnectWhitelabelMiddleware } from '@weavelab/frontend-connect-whitelabel-middleware';
import { litWhitelabelMiddleware } from '@weavelab/lit-whitelabel-middleware';
import { setupWhitelabel } from '@weavelab/whitelabel';
import { isMobile } from './helpers/mobile';
import { cssApplyPatchWhitelabelMiddleware } from './patches/cssApplyPatchWhitelabelMiddleware';

// this function will strip off the version if a vendor still has a version in the apiUrl
const stripOffEndpoindVersion = (apiLink) => {
    let newApiLink = apiLink;
    if (apiLink && apiLink.includes('/v1')) {
        [newApiLink] = apiLink.split('/v1');
    }
    return newApiLink;
};

if (window.location.protocol === 'file:') {
    const pathName = String(window.location.pathname);
    const path = pathName.substring(0, pathName.indexOf('/www/'));
    if (isMobile.iOS()) {
        provider.setAssetRoot(`${path}/www/vendor_theme/assets`);
    } else {
        provider.setAssetRoot('file:///android_asset/www/vendor_theme/assets');
    }
}

if (typeof cordova !== 'undefined' && cordova.InAppBrowser) {
    console.warn('Replacing window.open with window.InAppBrowser.open');
    window.open = cordova.InAppBrowser.open;
}

if (typeof window !== 'undefined') {
    window.VENDOR = provider.vendorId;
    window.APP_URL = provider.appUrl;
    window.API_LINK = 'https://prerelease.energyzero.staging.weave.nl';

    if (window.cordova) {
        window.API_LINK = 'https://api.energyzero.nl';
    } else if (window.env?.backendUrl) {
        window.API_LINK = stripOffEndpoindVersion(window.env.backendUrl);
    }
    window.APP_URL = window.env.appUrl;

    if (window.env) {
        window.VENDOR = window.env.vendorId;
        window.APP_URL = window.env.appUrl;
        window.dev = window.env.dev;
    }

    // fallback for frontend preview
    if (/\/.preview.staging.energyzero.nl\//.test(window.APP_URL)) {
        window.APP_URL = window.location.origin;
    }
}

// Initialize whitelabel
//   with lit-whitelabel-middleware              - to allow style loading from themes
//   with frontend-connect-whitelabel-middleware - to load settings from old frontend-connect themes
setupWhitelabel(
    litWhitelabelMiddleware,
    cssApplyPatchWhitelabelMiddleware,
    frontendConnectWhitelabelMiddleware(provider),
);
